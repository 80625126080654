/* Chat message styles */
.message-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0; /* Add margin to create space between messages */
    padding: 10px; /* Add padding inside each message container */
    border-radius: 10px;
    align-items: flex-start; /* Align messages to the left by default */
  }
  /* Add CSS styles to create a scrollbar */
.message-list {
    max-height: 400px; /* Set a fixed maximum height for the container */
    overflow-y: auto; /* Add vertical scrollbar when content overflows */
  }
  
  
  /* Style for admin messages */
  .message.admin {
    border-radius: 10px;
    padding: 10px;
    background-color: #007bff; /* Blue background color for admin messages */
    color: white; /* White text color for admin messages */
    align-self: flex-start; /* Align admin messages to the left */
  }
  
  /* Style for user messages */
  .message.user {
    border-radius: 10px;
    padding: 10px;
    background-color: #e5e5e5; /* Gray background color for user messages */
    color: #333; /* Dark text color for user messages */
    align-self: flex-end; /* Align user messages to the right */
  }
  